import { useQuery } from 'react-query';
import { type StoryPagesResponse } from '../dtos/StoryPagesDto';
import { fetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

const useStoryPages = (year?: number) => {
  return useQuery(API_URLS.STORY_LIST(year ?? 2023), async () => {
    try {
      const response = await fetcher.get<StoryPagesResponse>(API_URLS.STORY_LIST(year ?? 2023));
      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useStoryPages;
