import { type PageResponse } from './PageDto';
import PageDto from './PageDto';

export type StoryPagesResponse = {
  ok: boolean;
  data: PageResponse[];
};

class StoryPagesDto {
  readonly pages: PageDto[];
  constructor (response: StoryPagesResponse) {
    this.pages = response.data.map((page) => new PageDto(page));
  }
}

export default StoryPagesDto;
