import { Box, Skeleton } from '@inflearn/ds-react';
import { styleCommonCardsSection, styleList } from '.';

const SkeletonStoryCard = () => {
  return (
    <Box>
      <Skeleton
        css={{
          overflow: 'hidden',
          position: 'relative',
          flexShrink: 0,
          height: '200px',
          display: 'block',
          borderRadius: 16
        }}></Skeleton>
      <Box
        css={{
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: '24px 8px',
          position: 'relative',
          alignContent: 'center',
          flexWrap: 'nowrap',
          gap: 12
        }}>
        <Skeleton width="100%" height="30px" />
        <Skeleton width="100%" height="54px" />
      </Box>
    </Box>
  );
};

export const SkeletonStoryCardList = () => {
  return (
    <Box component="section" css={styleCommonCardsSection}>
      <Box component="ul" css={styleList}>
        <SkeletonStoryCard />
        <SkeletonStoryCard />
        <SkeletonStoryCard />
        <SkeletonStoryCard />
        <SkeletonStoryCard />
        <SkeletonStoryCard />
      </Box>
    </Box>
  );
};

export default SkeletonStoryCardList;
