import { css } from '@emotion/react';
import { Box, desktopFirstMediaQuery } from '@inflearn/ds-react';
import { motion } from 'framer-motion';
import { map } from 'lodash-es';
import { commonSectionItemVariants } from '../../main/sections/InteractionSections/variants';
import StoryCard from './StoryCard';
import type PageDto from '../../../../dtos/PageDto';
import { URLS } from '../../../../utils/urls';

type InfoCardsProps = {
  cards: PageDto[];
};
const StoryCards = ({ cards }: InfoCardsProps) => {
  return (
    <Box component={'section'} css={styleCommonCardsSection}>
      <motion.ul initial="offscreen" whileInView="onscreen" css={styleList}>
        {map(cards, (card) => {
          return (
            <motion.li
              key={card.slug}
              initial="offscreen"
              whileInView="onscreen"
              variants={commonSectionItemVariants({ delay: 0.2 })}
              css={styleListItem}>
              <StoryCard
                title={card.title}
                description={card.body}
                image={card.thumbnailUrl}
                url={URLS.INFCON_2023_EVENT_DETAIL(card.slug)}
              />
            </motion.li>
          );
        })}
      </motion.ul>
    </Box>
  );
};

export const styleCommonCardsSection = css({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  alignItems: 'start',

  width: '100%',
  overflowX: 'hidden'
});

export const styleList = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: '64px',
  columnGap: '32px',

  margin: '40px 0 80px',
  padding: '32px',
  width: '100%',
  maxWidth: '1200px',

  [desktopFirstMediaQuery.tablet]: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [desktopFirstMediaQuery.mobile]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    rowGap: '48px',
    columnGap: '16px',

    margin: '20px 0 60px',
    padding: '16px'
  }
});

const styleListItem = css({
  margin: 0,
  padding: 0,
  width: '100%'
});

export default StoryCards;
