import { css } from '@emotion/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { Box, Ellipsis, Icon, Text, Title } from '@inflearn/ds-react';
import { unescape } from '../../../../utils/escape';
import { styleCard, styleCardImage, styleCardImageWrapper } from '../EventCard/EventCard';

type Props = {
  url: string;
  image: string;
  title: string;
  description: string;
};

const StoryCard = ({ url, image, title, description }: Props) => {
  return (
    <a
      href={url}
      target={'_blank'}
      rel='noreferrer'>
      <Box
        component='article'
        css={[styleCard, {
          boxShadow: 'none',
          borderRadius: 0,
          transition: 'transform .25s ease-in-out',
          '&:hover': {
            transform: 'translateY(-6px)',
            svg: {
              opacity: 0.5
            },
            img: {
              filter: 'brightness(0.8)'
            }
          }
        }]}>
        <Box component='figure' css={[styleCardImageWrapper, styleStoryCardImageWrapper]}>
          <img src={image} alt={`${title} 썸네일`} css={styleCardImage} />
          <Icon
            icon={faArrowUpRightFromSquare}
            size={40}
            css={{ position: 'absolute', opacity: 0, transition: 'opacity .25s ease-in-out' }}
            color='white'
          />
        </Box>
        <Box css={styleStoryCardInfo}>
          <Title color='#001122' order={2} size={22} align='left' weight='bold'>
            {unescape(title)}
          </Title>
          <Ellipsis lineClamp={2}>
            <Text color='gray.5' size={18} weight='medium'>
              {unescape(description)}
            </Text>
          </Ellipsis>
        </Box>
      </Box>
    </a>
  );
};

const styleStoryCardInfo = css({
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '24px 8px',
  position: 'relative',
  alignContent: 'center',
  flexWrap: 'nowrap',
  gap: 12,
  borderRadius: '0px 0px 0px 0px'
});

const styleStoryCardImage = css({
  position: 'absolute',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
});

const styleStoryCardImageWrapper = css({
  borderRadius: 16
});

export default StoryCard;
