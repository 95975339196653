import { Box } from '@inflearn/ds-react';
import { type HeadFC, navigate } from 'gatsby';
import CommonHero, { HERO_IMAGE_NAME } from '../../../../components/infcon-2023/commons/CommonHero';
import StoryPagesDto from '../../../../dtos/StoryPagesDto';
import { useMemo, useEffect } from 'react';
import useStoryPages from '../../../../hooks/useStoryPages';
import Seo from '../../../../utils/Seo';
import { GATSBY_SITE_URL } from '../../../../utils/env';
import { URLS } from '../../../../utils/urls';
import StoryCards from '../../../../components/infcon-2023/commons/StoryCard';
import SkeletonStoryCardList from '../../../../components/infcon-2023/commons/StoryCard/SkeletonStoryCard';

const Infcon2023StoryPage = () => {
  const { data, isLoading, isError } = useStoryPages();

  const stories = useMemo(() => {
    if (isLoading || data == null) {
      return [];
    }

    return new StoryPagesDto(data).pages;
  }, [isLoading, data]);

  useEffect(() => {
    if (isError) {
      void navigate('/404');
    }
  }, [isError]);
  return (
    <Box component="section">
      <CommonHero
        title={'인프콘 스토리'}
        description={'인프콘의 다양한 이야기들을 만나보세요'}
        heroImageName={HERO_IMAGE_NAME.story}
      />
      {isLoading ? <SkeletonStoryCardList /> : <StoryCards cards={stories} />}
    </Box>
  );
};

export const Head: HeadFC = () => {
  return (
    <Seo
      title="인프콘 2023 스토리 - INFCON 2023"
      description="인프콘의 다양한 이야기를 만나보세요 - 인프런이 만드는 IT인의 축제, 인프콘으로 초대합니다. 인프콘과 함께 배우고 나누고 성장하세요. 다양한 발표 세션, 핸즈온, 네트워킹까지 만나보세요."
      fullUrl={`${GATSBY_SITE_URL}${URLS.INFCON_2023__STORY}`}
    />
  );
};

export default Infcon2023StoryPage;
